

  export default {
    name: `MyCheckCrifStatus`,
    methods: {
      show: () => {return [`ordered_placed`].includes(this.myCheck?.status.code)}
    },
    props: {
      myCheck: {
        type: Object,
        default: undefined
      }
    },
  }

