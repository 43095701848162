

  export default {
    name: `MyCheckMedicalTestStatus`,
    methods: {
      show: () => {return [`in_progress`, `cancelled`].includes(this.myCheck?.status.code)}
    },
    props: {
      myCheck: {
        type: Object,
        default: undefined
      }
    },
  }

